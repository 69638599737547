import { object, string, InferType } from 'yup'
import { gql, useMutation } from '@apollo/client'
import { FC, ReactNode, useCallback } from 'react'
import { useFormik } from 'formik'

import { EMAIL } from 'constants/schema'
import { USER_BASE_DATA } from 'gql/queries'
import { LoginMutation, LoginMutationVariables, UserNode } from 'gql'
import { useQueryParams, useDataLayer } from 'hooks'
import SignInFormComponent from './component'

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!, $cartId: ID) {
    login(input: { email: $email, password: $password, cartId: $cartId }) {
      ... on LoginSuccess {
        user {
          pk
          id
          email
          type
          lastName
          firstName
          fullName
          phoneNumber
          isPhoneNumberVerified
        }
      }
      ... on LoginFailure {
        errorMessage
        fieldsErrors {
          field
          messages
        }
      }
    }
  }
`

const validationSchema = object({
  password: string().required('Password is required!'),
  email: EMAIL(),
}).required()

type SignInFormValues = InferType<typeof validationSchema>

export type SignInFormProps = {
  header?: ReactNode
  onCompleted?: (user: Pick<UserNode, 'isPhoneNumberVerified'>) => void
  initialValues?: Partial<SignInFormValues>
}

const SignInForm: FC<SignInFormProps> = ({ header, onCompleted, initialValues }) => {
  const {
    paths,
    push,
    withQueryParams,
    query: { redirect, auction, bid, reassignCart, follow },
  } = useQueryParams()

  const [mutateLogin] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN, {
    refetchQueries: [USER_BASE_DATA],
  })
  const dl = useDataLayer()

  const onSubmit = useCallback(
    (values, { setStatus }) => {
      mutateLogin({
        variables: { ...values, cartId: reassignCart },
      }).then((res) => {
        if (res.data.login.__typename === 'LoginFailure') {
          setStatus(
            res.data.login.errorMessage || 'An error occurred while logging in. Please try again.'
          )
          return
        }

        dl.push({
          event: 'login',
          method: 'email-pass',
        })

        if (onCompleted) {
          onCompleted({ isPhoneNumberVerified: res.data.login.user.isPhoneNumberVerified })
          return
        }

        if (auction && bid) {
          push({ pathname: paths.CONFIRM_BID, query: { auction, bid } })
          return
        }

        if (follow) {
          push({ pathname: redirect, query: { follow: true } })
          return
        }

        push(redirect || paths.HOME)
      })
    },
    [dl, mutateLogin, paths.CHECKOUT, paths.HOME, push, redirect]
  )

  const { handleSubmit, handleChange, handleBlur, values, status, errors, touched } =
    useFormik<SignInFormValues>({
      initialValues: {
        email: '',
        password: '',
        ...initialValues,
      },
      validationSchema,
      onSubmit,
    })

  return (
    <SignInFormComponent
      header={header}
      values={values}
      status={status}
      errors={errors}
      touched={touched}
      handleBlur={handleBlur}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      forgotPassword={paths.FORGOT_PASSWORD}
      joinLink={withQueryParams(paths.JOIN, { redirect, auction, bid })}
    />
  )
}

export default SignInForm
