import { FC, ReactNode } from 'react'
import { InputPassword } from '~shared'
import { GlowHeading, Link, Typography, Input, Grid } from '~ui'

import styles from '../AuthForm.module.scss'

const { Text } = Typography

const LOGIN_ERROR_TYPES = {
  PASSWORD_IS_NOT_SET: 'Password is not set',
}

const LOGIN_ERROR_MESSAGE = {
  [LOGIN_ERROR_TYPES.PASSWORD_IS_NOT_SET]:
    "Finish setting up your account by resetting your password. Click 'Forgot Your Password?' above to start.",
}

type SingInFormComponentProps = {
  header?: ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any
}

const SingInFormComponent: FC<SingInFormComponentProps> = ({
  values,
  status,
  errors,
  touched,
  joinLink,
  handleBlur,
  handleSubmit,
  handleChange,
  forgotPassword,
  header,
}) => (
  <form onSubmit={handleSubmit} className={styles['form-wrapper']}>
    <Grid gap="1rem">
      {header || (
        <>
          <GlowHeading center Tag="h2" size="h1">
            Welcome back
          </GlowHeading>
          <Text center>
            Don&apos;t have an account?{' '}
            <Link href={joinLink} color="green">
              Sign Up
            </Link>
          </Text>
        </>
      )}

      <div className={styles['form-inputs']}>
        <Input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && errors.email}
          placeholder="Email Address"
        />

        <InputPassword
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="current-password"
          value={values.password}
          error={touched.password && errors.password}
        />
      </div>

      <div className={styles['forgot-password-link']}>
        <Link href={forgotPassword}>Forgot your password?</Link>
      </div>

      {!!status && <Text color="red">{LOGIN_ERROR_MESSAGE[status] || status}</Text>}

      <button data-test-id="sign-in-button" className="btn btn-primary w-full" type="submit">
        Sign In
      </button>
    </Grid>
  </form>
)

export default SingInFormComponent
