import { NextPage } from 'next'

import SignInForm from 'components/auth/sign-in'
import { MetaTags } from '~shared'
import { Layout } from '~ui'

const JoinPage: NextPage = () => (
  <Layout>
    <MetaTags title="Login">
      <meta name="robots" content="noindex, nofollow" />
    </MetaTags>
    <Layout.Header />
    <Layout.Main withPadding withSidebar={false} backgroundColor="g-black">
      <SignInForm />
    </Layout.Main>
  </Layout>
)

export default JoinPage
